import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { LanguageEnum, LocalStrings } from "./helpers/LanguageHelper";
import en from "./localization/en.json";
import it from "./localization/it.json";
import de from "./localization/de.json";
import pt from "./localization/pt.json";
import es from "./localization/es.json";

// the translations
// to move them in a JSON file and import them, use Backend plugin from the i18next-http-backend package
// for now it's ok like this
const resources: {[key in LanguageEnum]: Partial<LocalStrings>} = {
  en: en,
  de: de,
  it: it,
  pt: pt,
  es: es,
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'it',
    defaultNS: 'common',
    //lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;