import en from "../localization/en.json";

export enum LanguageEnum {
    English = 'en',
    Italian = 'it',
    Deutsch = 'de',
    Portuguese = 'pt',
    Spanish = 'es',
};

export const LanguageName: { [key in LanguageEnum]: string} = {
    'en': "English",
    'it': "Italiano",
    'de': "Deutsch",
    'pt': "Portuguese",
    'es': "Spanish",
};

export const languages = Object.values(LanguageEnum).sort();

export type LocalStrings = typeof en;

export const isLanguageValid = (langs: (string|undefined)[] | undefined) => {
    if(langs !== undefined && langs.length > 0) {
        return langs.every((lang) => lang !== undefined && Object.values(LanguageEnum).includes(lang as LanguageEnum));
    }
    else return false
}
