export enum CountryEnum {
    Italy = 'it',
    Deutschland = 'de',
    Brasil = 'br',
    Argentina = 'ar',
};

export const CountryName: { [key in CountryEnum]: string } = {
    'it': "Italia",
    'de': "Deutschland",
    'br': "Brasil",
    'ar': "Argentina",
};

export const countries = Object.values(CountryEnum).sort();

export const isCountryValid = (country: string | undefined) => {
    console.log("isCountryValid: ", country !== undefined && Object.values(CountryEnum).includes(country as CountryEnum));
    return country !== undefined && Object.values(CountryEnum).includes(country as CountryEnum);
}

export const getSupportLinkByCountryCode = (country: CountryEnum) => {
    switch (country) {
        case CountryEnum.Italy:
            return process.env.REACT_APP_SupportPageLink_IT ?? "";
        case CountryEnum.Deutschland:
            return process.env.REACT_APP_SupportPageLink_DE ?? "";
        case CountryEnum.Brasil:
            return process.env.REACT_APP_SupportPageLink_BR ?? "";
        case CountryEnum.Argentina:
            return process.env.REACT_APP_SupportPageLink_AR ?? "";
        default:
            return "";
    }
}

export const getTeamsInstallManualByCountryCode = (country: CountryEnum) => {
    switch (country) {
        case CountryEnum.Italy:
            return process.env.REACT_APP_Teams_Install_Manual_IT ?? "";
        case CountryEnum.Deutschland:
            return process.env.REACT_APP_Teams_Install_Manual_DE ?? "";
        case CountryEnum.Brasil:
            return process.env.REACT_APP_Teams_Install_Manual_BR ?? "";
        case CountryEnum.Argentina:
            return process.env.REACT_APP_Teams_Install_Manual_AR ?? "";
        default:
            return "";
    }
}

export const getServiceMailByCountryCode = (country: CountryEnum) => {
    switch (country) {
        case CountryEnum.Italy:
            return process.env.REACT_APP_ServiceMail_IT ?? "";
        case CountryEnum.Deutschland:
            return process.env.REACT_APP_ServiceMail_DE ?? "";
        case CountryEnum.Brasil:
            return process.env.REACT_APP_ServiceMail_BR ?? "";
        case CountryEnum.Argentina:
            return process.env.REACT_APP_ServiceMail_AR ?? "";
        default:
            return "";
    }
}

export const getServicePhoneByCountryCode = (country: CountryEnum) => {
    switch (country) {
        case CountryEnum.Italy:
            return process.env.REACT_APP_ServicePhone_IT ?? "";
        case CountryEnum.Deutschland:
            return process.env.REACT_APP_ServicePhone_DE ?? "";
        case CountryEnum.Brasil:
            return process.env.REACT_APP_ServicePhone_BR ?? "";
        case CountryEnum.Argentina:
            return process.env.REACT_APP_ServicePhone_AR ?? "";
        default:
            return "";
    }
}

export const getInfoPointPhoneByCountryCode = (country: CountryEnum) => {
    switch (country) {
        case CountryEnum.Italy:
            return process.env.REACT_APP_InfoPoint_Phone_IT ?? "";
        case CountryEnum.Deutschland:
            return process.env.REACT_APP_InfoPoint_Phone_DE ?? "";
        case CountryEnum.Brasil:
            return process.env.REACT_APP_InfoPoint_Phone_BR ?? "";
        case CountryEnum.Argentina:
            return process.env.REACT_APP_InfoPoint_Phone_AR ?? "";
        default:
            return "";
    }
}

export const getGDPRFileByCountryCode = (country: CountryEnum) => {
    switch (country) {
        case CountryEnum.Italy:
            return process.env.REACT_APP_GDPR_File_IT ?? "";
        case CountryEnum.Deutschland:
            return process.env.REACT_APP_GDPR_File_DE ?? "";
        case CountryEnum.Brasil:
            return process.env.REACT_APP_GDPR_File_BR ?? "";
        case CountryEnum.Argentina:
            return process.env.REACT_APP_GDPR_File_AR ?? "";
        default:
            return "";
    }
}