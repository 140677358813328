import React from 'react';
import { CountryConfig } from './interfaces/Config';

interface CountryGridProps {
    countries: CountryConfig[];
}

const CountryGrid = ({ countries }: CountryGridProps) => {
    return (
        <div className="Container-Main">
            <div style={{ cursor: 'pointer' }} onClick={() => window.location.replace(`${window.location.origin}`)}>
                <img src={`${process.env.REACT_APP_SiteUrl}/media/images/CNH_Header.png`} className="App-logo" alt="logo" />
            </div>
            <div className="App">
                <header className="App-header">
                    <div className="App_body">
                        <div className={"panel_onboard"}>
                            Select your Country:
                            <div style={styles.gridContainer}>
                                {countries.map((country, index) => (
                                    <a href={`${window.location.href}${country.Country}/onboard/${country.DefaultLang}`} style={styles.link}>
                                        <div key={index} style={styles.countryContainer}>
                                            <img src={country.FlagUrl} alt={`${country.Country} flag`} style={styles.flag} />
                                            <p>{country.Name}</p>
                                        </div>
                                    </a>
                                ))}
                            </div>
                        </div>
                    </div>
                </header>
            </div>
        </div>
    );
};

const styles = {
    gridContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        gap: '30%',
        padding: '0px 20% 0px 20%'
    } as React.CSSProperties,
    countryContainer: {
        textAlign: 'center'
    } as React.CSSProperties,
    flag: {
        width: '50px',
        height: '50px',
        borderRadius: '50%',
        objectFit: 'cover',
        objectPosition: 'center'
    } as React.CSSProperties,
    link: {
        textDecoration: 'none',
        color: 'inherit'
    } as React.CSSProperties
};

export default CountryGrid;
