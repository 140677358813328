import { UserData, UserMailData } from "../Types";
import { CountryEnum } from "../helpers/CountryHelper";
import { LanguageEnum } from "../helpers/LanguageHelper";

export interface CountryConfig {
  Country: CountryEnum;
  Name: string;
  AvailableLangs: LanguageEnum[];
  DefaultLang: LanguageEnum;
  FlagUrl: string;
}

export function setDefaultLang(data: any[]): CountryConfig[] {
  return data.map(item => ({
    ...item,
    DefaultLang: item.AvailableLangs[0]
  }));
}