import React from 'react';
import { LangPageProps } from './interfaces/ComponentProps';

function HelpRedirect({ lang }: LangPageProps) {
  React.useEffect(() => {
    const currentPath = window.location.pathname;
    const basePath = currentPath.split('/').slice(0, 2).join('/');

    window.location.replace(`${basePath}/help/${lang}`);
  }, [lang]);

  return null;
}

export default HelpRedirect;
