import React from 'react';
import { LangPageProps } from './interfaces/ComponentProps';

function HomePage({ lang }: LangPageProps) {
  let currentPath = window.location.pathname;

  if (currentPath.endsWith('/')) {
    currentPath = currentPath.slice(0, -1);
  }

  React.useEffect(() => {
    window.location.replace(`${currentPath}/onboard/${lang}`);
  });

  return (
    <></>

  );

}


export default HomePage;