import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { DefaultButton } from "@fluentui/react";
import { Trans, useTranslation } from "react-i18next";
import { getGDPRFileByCountryCode, getServicePhoneByCountryCode } from "./helpers/CountryHelper";
import { StepPolicyProps } from "./interfaces/ComponentProps";
import i18n from "./i18n";

const StepPolicyComponent = ({
  nextButtonText,
  onNext,
  onValidate,
  lang,
  country
}: StepPolicyProps) => {
  const { t } = useTranslation(['onBoard']);
  const recaptchakey: string = process.env.REACT_APP_SITE_KEY ?? "";
  const [Recaptchachecked, setRecaptcha] = useState(false);
  const [humankey, setRecaptchaValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);

  const gdprFile = getGDPRFileByCountryCode(country);
  const servicePhone = getServicePhoneByCountryCode(country);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang])

  const onChangePrivacy = () => {
    setChecked(!checked);
  };

  useEffect(() => {
    onValidate(humankey);
  }, [humankey]);

  async function onChangeRecaptcha(value: any) {
    setRecaptchaValue(value);
    setRecaptcha(true);
  }

  function onTimeoutExpired(value: any) {
    setRecaptcha(false);
  }

  async function handleNext() {
    setLoading(true);
    await onNext("policy");
    setLoading(false);
  }

  return (
    <div>
      {" "}
      <Trans t={t} i18nKey="pageOnboardText_body" values={{servicePhoneShow: servicePhone, servicePhone: servicePhone.replace("-", "") }} components={{ a: <a/>,b: <b />, p: <p />, span: <span /> }} />
      <p className="privacyCheck">
        <input
          aria-label="privacyCheck"
          type="checkbox"
          onChange={onChangePrivacy}
        />
        <Trans t={t} i18nKey="pageOnboardPrivacyText_firstpart" />
        <a
          href={gdprFile}
          download
        >
          {" "}
          <Trans t={t} i18nKey="pageOnboardPrivacyLinkText" />{" "}
        </a>
      </p>
      <p><Trans t={t} i18nKey="pageOnboardPrivacyText_secondpart" /></p>
      <div className="div_captcha">
        <ReCAPTCHA
          size="normal"
          data-theme="dark"
          sitekey={recaptchakey}
          onChange={onChangeRecaptcha}
          onExpired={() => {
            setRecaptcha(false); // here
          }}
          onTimeUpdate={onTimeoutExpired}
        />
      </div>
      <div className="div_button">
        <DefaultButton
          className={
            checked && Recaptchachecked && !loading
              ? "button"
              : "button_disable"
          }
          onClick={async () => await handleNext()}
          disabled={!checked || !Recaptchachecked || loading}
        >
          {nextButtonText}
        </DefaultButton>
      </div>
    </div>
  );
};
export const StepPolicy = StepPolicyComponent;
