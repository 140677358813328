import React from "react";

export function ErrorMessage({errorMessage}: {errorMessage: string}) {
  return (
    <div>
      <p className="error">
        <strong>{errorMessage}</strong>
      </p>
    </div>
  );
}
