import { UserData, UserMailData, ValidationResult } from "../Types";
import { CountryEnum } from "./CountryHelper";
import { LanguageEnum } from "./LanguageHelper";
import { SiteHelper } from "./SiteHelper";
import validator from "validator";
import i18n from "../i18n";
import { TFunction } from "i18next";


async function ValidateRecaptcha(humanKey:string): Promise<ValidationResult> {
    var checkRecaptcha: boolean = false;
    var checkRecaptcha_message: string = "";
    checkRecaptcha_message = await SiteHelper.GetRecaptchaValidationResponse(
      humanKey
    );
    if (!checkRecaptcha_message) {
      checkRecaptcha = true;
    } 
    return { isValid: checkRecaptcha, message: checkRecaptcha_message };
  }

  async function ValidateUserData(userData:UserData, t: TFunction<[string], undefined>, lang: LanguageEnum, country: CountryEnum): Promise<ValidationResult> {
    i18n.changeLanguage(lang);

    var result: ValidationResult = { isValid: false, message: "" };
    if (
      validator.isEmpty(userData.ssn) ||
      validator.isEmpty(userData.badgeNumber)
    ) {
      if (
        validator.isEmpty(userData.ssn) &&
        validator.isEmpty(userData.badgeNumber)
      ) {
        result = {
          isValid: false,
          message: SiteHelper.ManageErrorMessage(t("badgeSSNMissing")),
        };
      } else {
        if (validator.isEmpty(userData.ssn)) {
          result = {
            isValid: false,
            message: SiteHelper.ManageErrorMessage(t("SSNMissing")),
          };
        }

        if (validator.isEmpty(userData.badgeNumber)) {
          result = {
            isValid: false,
            message: SiteHelper.ManageErrorMessage(t("badgeMissing")),
          };
        }
      }
    } else {
      const errMsg = await SiteHelper.CheckUser(
        userData.badgeNumber,
        userData.ssn,
        country,
        lang
      );

      const trimmedMsg = !validator.isEmpty(errMsg) ? errMsg.substring(3) : "";

      if (validator.isEmpty(trimmedMsg)) {
        result = { isValid: true, message: "" };
      } else {
        result = { isValid: false, message: trimmedMsg };
      }
    }
    return result;
  }

  async function ValidateMailData(userMailData:UserMailData, t: TFunction<[string], undefined>, lang: LanguageEnum): Promise<ValidationResult> {
    i18n.changeLanguage(lang);

    var result: ValidationResult = { isValid: false, message: "" };
    if (validator.isEmpty(userMailData.email)) {
      result = {
        isValid: false,
        message: SiteHelper.ManageErrorMessage(t("mailIsEmpty")),
      };
    } else if (!validator.isEmail(userMailData.email)) {
      result = {
        isValid: false,
        message: SiteHelper.ManageErrorMessage(t("mailIsEmpty")),
      };
    } else if (userMailData.email !== userMailData.emailCheck) {
      result = {
        isValid: false,
        message: SiteHelper.ManageErrorMessage(t("mailCheck")),
      };
    } else {
      result = { isValid: true, message: "" };
    }
    return result;
  } 

    export const Validation = {
        ValidateRecaptcha,
        ValidateUserData,
        ValidateMailData
    };